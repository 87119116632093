import { Theme, alpha } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { useBlendColors } from 'hooks/useDesign';

export const globalStylesData = (theme: Theme) => {
    return {
        '.highcharts-scrollbar': {
            display: 'none',
        },
        a: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '.invert-link': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none',
            },
        },
        '.color-primary': { color: theme.palette.primary.main },
        '.t-red, .color-error': { color: theme.palette.error.main },
        '.t-green, .color-success': { color: theme.palette.success.main },
        '.t-brown, .color-warn-solid': { color: '#B54C29' },
        '.fw-bold': {
            fontWeight: '700 !important',
            '& .MuiFormControlLabel-root .MuiFormControlLabel-label': { fontWeight: 'inherit' }, //checbox label change fw
        },
        '.fw-normal': { fontWeight: 400 },
        '.font-default': { fontFamily: theme.font.default },
        '.font-monospace': { fontFamily: theme.font.monospace },
        '.reflect-x': { transform: 'scale(-1,1)' },
        '.reflect-y': { transform: 'scale(1,-1)' },
        '.reflect-xy': { transform: 'scale(-1,-1)' },
        '.label-lh-1-1': { p: { lineHeight: 1.1 } },
        '.icon-type--': {
            //ToDo: change to auto init in cell component  like popup or link
            '&grid-action': {
                color: theme.palette.text.primary,
                'svg&': { fontSize: '16px' },
                '&:hover': { color: theme.palette.primary.main },
            },
        },
        //ToDo refactor wrapper for common case
        '.icon-cell-holder': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '14px',
            height: '14px',
            flexShrink: 0,
            img: {
                maxWidth: '100%',
                maxHeight: '100%',
            },
        },
        '.icon-color--': {
            '&metric': { color: '#808c10' },
            '&multi-metric': { color: '#be4e34' },
            '&report': { color: '#669898' },
            '&external-report': { color: '#333333' },
            '&external-content': { color: '#333333' },
        },
        //tech class for form grid
        '.form-components-indent': {
            '&>:not(.d-none):not(.d-none-empty-grid)~:not(style):not(.d-none):not(.d-none-empty-grid):not(.container-remove-indent)':
                {
                    marginTop: '17px',
                },
        },
        '.container--info': {
            borderRadius: '4px',
            border: `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
            background: alpha(theme.palette.text.primary, 0.02),
            padding: '12px', //todo change to coeff

            //workaround /admin/owner. need to refactor
            '&.container--info-remove-first-indent': {
                marginTop: '0',
                '& > *:first-of-type': {
                    paddingTop: '0 !important',
                },
            },
        },
        //hotfix form indent
        '.vertical-form-indent': {
            marginTop: '17px !important',
            marginBottom: '17px !important',
        },

        '.border-wrapper': {
            border: `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
            borderRadius: '3px',
            padding: '12px', //todo change to coeff
        },

        '.static-label-border': {
            borderBottom: `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
        },
        '@keyframes akf-spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
        },
        '.anim-spin': {
            animation: 'akf-spin 2s linear infinite',
        },
        '@keyframes akf-fadeIn': {
            '0%': { opacity: 0 },
            '100%': { opacity: 1 },
        },
        '.anim-fadeIn': {
            animation: 'akf-fadeIn 1s both',
        },
        '@keyframes akf-pulsing': {
            '0%': { opacity: 0.48 },
            '100%': { opacity: 1 },
        },
        '.anim-pulsing': {
            animation: 'akf-pulsing 1s ease-out infinite alternate',
        },
        '.d-flex': {
            display: 'flex',
        },
        '.d-none, .d-none-empty-grid': {
            display: 'none !important',
        },
        '.form-tab.d-none, .form-tab.form-tab--more-hide': {
            display: 'inline-flex !important',
            visibility: 'hidden',
            pointerEvents: 'none',
            position: 'absolute',
        },
        '.align-items-center': {
            alignItems: 'center',
            '& > .form-switch': { alignSelf: 'auto' },
        },
        '.align-items-end': {
            alignItems: 'end',
        },
        '.align-self-end': {
            alignSelf: 'end',
        },
        '.align-self-center': {
            alignSelf: 'center',
        },
        '.mr-auto': { marginRight: 'auto !important' },
        '.ml-auto': { marginLeft: 'auto !important' },
        '.align-as-field': {
            '.form-wrapper-empty-control, &-value': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                minHeight: theme.size.defaultHeight,
            },
            '&.without-label': {
                '.form-wrapper-empty-control': {
                    marginTop: '22px',
                },
            },
            '&.like-disabled-field': {
                backgroundColor: alpha(theme.palette.text.primary, 0.08),
                borderRadius: '3px',
                color: alpha(theme.palette.text.primary, 0.64),
                padding: '0 12px',
            },
        },
        '.checkbox-note': {
            paddingLeft: '24px !important', //checkbox 16px + indent 8px
            marginTop: '-19px !important', //decrease indent to 2px  = (default field 5px + indent 16):
            '&.under-text-field': {
                paddingTop: '5px',
            },
        },
        '.align-by-checkbox': {
            paddingLeft: '24px !important', //checkbox 16px + indent 8px
            '&.under-checkbox': {
                marginTop: '-9px !important', //decrease indent to 12px  = (default field 5px + indent 16):
                '&.MuiGrid-container': {
                    marginTop: '-22px !important', //-16px grid set + -9px indent (default field 5px + indent 16)
                },
            },
        },
        '.MuiContainer-root.form-container--maxfull': {
            //fullscreen width for form
            width: 'auto',
            marginLeft: theme.size.pxValue(theme.size.containerIndentX * -1),
            marginRight: theme.size.pxValue(theme.size.containerIndentX * -1),
            alignSelf: 'stretch',
        },
        '.bg-color--': {
            '&success-light': { backgroundColor: useBlendColors(alpha(theme.palette.success.main, 0.08)) },
            '&error-light': { backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.08)) },
        },
        '#page-scroll-grid-sticky': {
            '.grid-footer--buttons-exist': {
                minHeight: theme.size.defaultHeight,
            },
            '.grid-footer--buttons-exist.grid-footer--sticky-mod .grid-footer-content-wrap': {
                backgroundColor: theme.palette.background.default,
                position: 'fixed',
                bottom: 0,
                paddingTop: '16px',
                paddingBottom: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                zIndex: 1000,
                left: 0,
                //right: 0,
                boxShadow: `0px -4px 4px 0px ${alpha(theme.palette.text.primary, 0.04)}`,
            },
            '.grid-footer--buttons-exist.grid-footer--sticky-mod:not(.grid--full-mod) .grid-footer-content': {
                maxWidth: '1128px',
            },
            '.grid-container.grid-container--sticky-mod .rs-table-scrollbar-horizontal': {
                position: 'fixed',
                bottom: '60px', //buttons block
            },
        },

        '.MuiPickersPopper-root': {
            '.MuiDialogActions-root': {
                justifyContent: 'center',
            },
            '.MuiList-root': {
                scrollbarWidth: 'none',
                '&:after': {
                    height: 'auto',
                },
            },
        },

        '.disabled-checkbox-cursor-mod--not-allowed .MuiCheckbox-root.Mui-disabled': {
            cursor: 'not-allowed',
        },
        // Utility class for development
        '.dev-yellow-color': {
            backgroundColor: '#EE0',
        },
        '.dev-font-red-color': {
            color: '#E00',
        },
        '.empty-label-component .MuiInputBase-root': {
            marginTop: '22px',
            // backgroundColor: '#FF0',
        },
        '.empty-label-component .MuiFormControlLabel-root': {
            marginTop: '22px',
            // backgroundColor: '#FF0',
        },
        '.empty-label-component .MuiToggleButtonGroup-root': {
            marginTop: '22px',
            // backgroundColor: '#FF0',
        },
        '.empty-label-component .form-static-label': {
            marginTop: '22px',
            // backgroundColor: '#FF0',
        },
        '.empty-label-component .MuiFormControl-root': {
            marginTop: '22px',
        },
        '.empty-label-double-line-component .form-static-label': {
            marginTop: '11px',
            // backgroundColor: '#FF0',
        },
        '.input-right-label': {
            paddingLeft: '6px !important',
            paddingTop: '23px !important',
        },
        '.fade-in': {
            transition: 'opacity 1s ease',
        },
        '.fade-out': {
            opacity: 0,
            transition: 'opacity 1s ease',
        },
        '.mi-table-row-disabled, .mi-table-row-disabled *': {
            color: alpha(theme.palette.text.primary, 0.4),
        },
        '.mi-table-row-error, .mi-table-row-error *': {
            color: theme.palette.error.main,
        },
        '.mi-table-row-error-solid': {
            '&, & *': {
                color: theme.palette.error.main,
            },
            '& *': {
                fontWeight: '600 !important',
            },
            '& .rs-table-cell': {
                backgroundColor: alpha(theme.palette.error.main, 0.08) + ' !important',
            },
            '& .rs-table-cell-first:before': {
                content: '""',
                width: '1px',
                height: '100%',
                backgroundColor: theme.palette.error.main,
                position: 'absolute',
                left: 0,
            },
        },
        '.mi-table-row-success, .mi-table-row-success *': {
            color: theme.palette.success.main,
        },
        '.mi-table-row-warning, .mi-table-row-warning *': {
            color: theme.palette.warning.main,
        },
        '.mi-table-row-queued, .mi-table-row-queued *': {
            color: 'turquoise',
        },
        'body .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)': {
            borderRightColor: '#eee',
        },
        '.form-static-label-notes': {
            color: theme.palette.success.main,
        },

        '.html-grid-cell': {
            '.change_log_del': {
                textDecoration: 'line-through',
            },
            '.change_log_ins': {
                backgroundColor: yellow.A100,
            },
        },
        '.code-pre-line > .MuiFormControl-root > .MuiBox-root:last-of-type': {
            whiteSpace: 'pre-line',
            padding: '5px',
            backgroundColor: theme.palette.grey[100],
            border: `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
            wordBreak: 'break-all',
            wordWrap: 'break-word',
            borderRadius: '4px',
        },
        '.react-virtualized--fix-scroll': {
            '&.ReactVirtualized__Grid': {
                overflow: 'auto !important',
            },
            '& .ReactVirtualized__Grid__innerScrollContainer': {
                overflow: 'visible !important',
            },
        },
        // TODO: move?
        '.block-wrapper-body-line': {
            spaceWrap: 'normal',
        },
        '.block-wrapper-body-line.even>span': {
            backgroundColor: '#fafafa',
        },
        '.wrapper-cell': {
            whiteSpace: 'normal !important',
            height: 'auto !important',
            wordWrap: 'break-word',
        },
        '.bold-cell': {
            fontWeight: 'bold',
        },
        '.italic-cell': {
            fontStyle: 'italic',
        },
        //slider align thumb by corners
        '.slider-mod--corner-thumb': {
            paddingLeft: '12px',
            paddingRight: '12px',
            '& .MuiSlider-rail': {
                width: 'calc(100% + 24px)',
                marginLeft: '-12px',
            },
            '& .MuiSlider-track': {
                paddingRight: '12px',
                height: '2px',
                boxSizing: 'content-box',
                '&[style*="left"]': {
                    marginLeft: '-12px',
                },
                '&[style*="right"]': {
                    marginRight: '-12px',
                },
            },
        },
        html: {
            height: '100%',
            overflowY: 'hidden', //turn of mac os bounce effect
            overflowX: 'auto',
            minWidth: '1160px',
            '.layout--flex': {
                height: '100%',
                '#mi-react-root': { height: '100%' },
            },
        },
        body: {
            '&.isDragActive.isDragActive--row': {
                cursor: 'row-resize',
            },
        },
        //preference avatar adjust
        '.upload-image-mod--avtar': {
            '& .upload-image-holder': {
                alignItems: 'center',
            },
            '& .preview-holder': {
                width: '50px',
                height: '50px',
                borderRadius: '4px',
                position: 'relative',
                overflow: 'hidden',
                marginRight: '16px',
                alignItems: 'center',
                justifyContent: 'center',

                '&:after': {
                    content: '""',
                    position: 'absolute',
                    inset: '0 0 0 0',
                    border: `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
                    borderRadius: '4px',
                    zIndex: 1,
                },
                '& img': {
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                },
            },
        },
        '.markdown-holder': {
            p: {
                margin: '0',
            },
            hr: {
                margin: '16px 0 0',
                border: 0,
                borderTop: `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
            },
            pre: {
                border: `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
                borderRadius: '3px',
                padding: '8px',
                backgroundColor: useBlendColors(alpha(theme.palette.error.main, 0.08)),
                color: theme.palette.error.main,
                fontFamily: theme.font.default,
            },
            '&>* + p, &>* + pre, &> pre + *': {
                marginTop: '12px',
            },
            a: {
                textDecoration: 'underline',
                '&:hover': {
                    textDecoration: 'none',
                },
            },
        },
        '.richtext-holder': {
            p: {
                margin: '0',
            },
            a: {
                textDecoration: 'underline',
                '&:hover': {
                    textDecoration: 'none',
                },
            },
            'ol, ul': {
                margin: 0,
                paddingLeft: '24px',
                li: { paddingLeft: 0 },
            },
            ul: { listStyleType: 'disc' },
            ol: { listStyleType: 'decimal' },
            'h2, h3': {
                margin: 0,
                padding: 0,
                fontWeight: 600,
            },
            h2: {
                fontSize: '1.5em',
            },
            h3: {
                fontSize: '1.17em',
            },
        },
        '.inline-edit--text-field': {
            '.MuiInput-root:not(.Mui-focused)': {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                '& .MuiInput-input::placeholder': {
                    color: theme.palette.primary.main,
                },
                '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                },
            },
        },
        '.inline-edit--select': {
            '.custom-select__value-container': {
                pr: 0,
            },
            '.custom-select__control:not(.custom-select__control--is-focused)': {
                borderColor: 'transparent',
            },
            '.custom_select_holder:hover .custom-select__control:not(.custom-select__control--is-focused)': {
                backgroundColor: alpha(theme.palette.primary.main, 0.04),
            },
            '& .custom_select_holder:not(:hover) .custom-select__control:not(.custom-select__control--is-focused)': {
                '.custom-select__dropdown-icon': {
                    display: 'none',
                },
            },
        },
        '.dHeaderResizable': {
            height: 'auto !important',
        },
        '.dHeaderResizable .wrapper-cell': {
            height: '100% !important',
        },

        //DatePicker position
        '.custom-datepicker__react-datepicker-popper': {
            zIndex: theme.zIndex.modal + 10,
        },
        //EXPERIMENTAL: /admin/search#concierge => Add External Resource Configuration
        // modify popup to full height with form containers
        '.popup-component.popup-component--full-height': {
            '& .popup-component__container': {
                height: '100vh',

                '.form-popup, .form-popup .form-popup__content, .scroll-content-container--form-popup': {
                    height: '100%',
                },
                '.flex-grow-component-container': {
                    flexGrow: 1,
                },
            },
        },
        'body.print-restriction': {
            '@media print': {
                visibility: 'hidden',
                display: 'none',
            },
        },
        '.action-menu-item--disabled': {
            pointerEvents: 'none',
        },
        '.network-error-message': {
            display: 'flex',
            '& .network-error-message__icon': {
                display: 'inline-flex',
                fontSize: '32px',
                color: alpha(theme.palette.text.primary, 0.4),
                '& .svg-icon-mi': {
                    userSelect: 'none',
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                    flexShrink: 0,
                    fill: 'currentColor',
                    fontSize: 'inherit',
                },
            },
            '&.network-error-message--default': {
                flexDirection: 'column',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                '& .network-error-message__icon': {
                    marginBottom: '12px',
                },
            },
            '&.network-error-message--grid': {
                flexDirection: 'row',
                '& .network-error-message__icon': {
                    marginRight: '12px',
                },
            },
        },
    };
};
